<template>
  <div class="swiperbox">
    <van-swipe v-if="control.type!=1" class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="(leve, index) in control.list" :key="index">

        <div class="levelListBox">
          <div v-if="leve.isCurrentLevel === '1'" class="currentLevel">
            <img src="@/assets/images/isCurrentLevel.png">
            <!-- {{ leve.isCurrentLevel}} -->
          </div>
          <div class="levelTitle">
            {{ leve.levelName }}
            <span>{{ leve.iconListInfo.equityAmount }}项</span>权益
          </div>
          <ul>

            <li v-for="(icon, i) in leve.iconListInfo.iconList" :key="i" @click="ckickitem(icon)">
              <!-- {{i}} -->
              <img :src="icon.iconImg">
              <p>{{ icon.name }}</p>
              <!-- {{icon.iconImg}} -->
            </li>
          </ul>
        </div>
      </van-swipe-item>

    </van-swipe>
    <!-- <van-swipe class="my-swipes" :autoplay="3000" v-else>
      <van-swipe-item v-for="(leve, index) in list" :key="index">
              <ul>
              <li v-for="(ele, i) in leve" :key="ele.name" @click="ckickitem(ele)">
                <img :src="ele.iconUrl" />
                <p>{{ ele.name }}</p>
              </li>
            </ul>
      </van-swipe-item>
    </van-swipe>   -->
  </div>
</template>

<script>

export default {
  components: {},
  props: {
    control: {
      type: Object,
      default: () => {
        return {}
      },
      require: true
    }
  },
  data() {
    // 这里存放数据
    return {
      parmas: {
        bgcolor: '',
        auto: ''
      },
      list: []
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.list.push(this.control.list.wapIcon)
    this.list.push(this.control.list.icon)
  },
  beforeCreate() {
  }, // 生命周期 - 创建之前
  beforeMount() {
  }, // 生命周期 - 挂载之前
  beforeUpdate() {
  }, // 生命周期 - 更新之前
  updated() {
  }, // 生命周期 - 更新之后
  beforeDestroy() {
  }, // 生命周期 - 销毁之前
  destroyed() {
  }, // 生命周期 - 销毁完成
  activated() {
  },
  // 方法集合
  methods: {
    ckickitem(icon) {
      this.control && this.control.ckickitem && this.control.ckickitem(icon)
    }
  } // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类
.my-swipe .van-swipe-item {
  color: #fff;
  height: 500px;
  font-size: 20px;
  //   line-height: 150px;
  text-align: center;

  .levelListBox {
    .currentLevel {
      //   width: ;
      width: 75px;
      height: 63px;
      position: absolute;
      top: 0;
      left: 0;
    }

    .levelTitle {
      width: 100%;
      padding: 0 0 20px 0;
      text-align: center;
      color: #fff;

      span {
        color: rgba(236, 190, 158, 1);
      }
    }

    position: relative;
    width: 100%;
    border: 1px solid #fff;
    padding: 20px 0 10px 0;
    border-radius: 10px;
    // height: 800px;

    ul {
      width: 100%;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap;

      li {
        //    width: 25%;
        width: 25%;
        height: 95px;
        text-align: center;
        // background: #000;
        img {
          width: 55px;
          height: 45px;
          //   height: 100%;
        }

        p {
          text-align: center;
          font-size: 12px;
          font-weight: bold;
          margin: 10px 0;
        }
      }
    }

  }

  //   background-color: #39a9ed;
  //   ul{
  //   width: 100%;
  //   height: 300px;
  //   display: flex;
  //   flex-wrap:nowrap;
  //   justify-content: center;
  //   align-items: center;
  //   li{
  //     width: 200px;
  //     height: 30%;
  //     // flex: 1;
  //     img{
  //       width: 100%;
  //       height: 50px;
  //     }
  //     p{
  //       width: 100%;
  //     }
  //   }
  // }
}

.my-swipes {
  width: 100%;
  height: 240px;

  ul {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow-y: hidden;
    overflow-X: auto;

    li {
      width: 20%;
      height: 30%;

      img {
        width: 50px;
        height: 50px;
      }
    }
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"MustBuyBox"},[(_vm.memberInfo)?_c('div',{staticClass:"MustBuyTop"},[_c('div',{staticClass:"userInterestsBox",style:({ backgroundImage: 'url(' + _vm.memberInfo.bgImg + ')' })},[_c('div',{staticClass:"userTopBox"},[_c('div',{staticClass:"Avatar"},[_c('img',{attrs:{"src":_vm.memberInfo.userarvr}})]),_c('div',{staticClass:"memberInfo"},[_c('h2',{style:({
              color: _vm.memberInfo.userIdentity === 3 ? '#E6C0A2' : '#05040a',
            })},[_c('div',{staticClass:"h2_box"},[_vm._v(_vm._s(_vm.memberInfo.nickname))]),_c('span',[_c('img',{attrs:{"src":_vm.memberInfo.icon}}),_vm._v(_vm._s(_vm.memberInfo.levelName))])]),_c('h4',{style:({
              color: _vm.memberInfo.userIdentity === 3 ? '#E6C0A2' : '#05040a',
            })},[_vm._v(" "+_vm._s(_vm.memberInfo.describe)+" ")])])]),_c('div',{staticClass:"userGrowth",style:({
          color: _vm.memberInfo.userIdentity === 3 ? '#E6C0A2' : '#05040a',
        })},[_vm._v(" 当前成长值："),_c('span',{style:({
            color: _vm.memberInfo.userIdentity === 3 ? '#E6C0A2' : '#05040a',
          })},[_vm._v(_vm._s(_vm.memberInfo.userGrowth))]),(_vm.memberInfo.levelName != '黑金VIP')?_c('div',{staticClass:"gotoupgrade",on:{"click":_vm.gotoupgrade}},[_vm._v(" 升级黑金VIP ")]):_vm._e()]),_c('div',{staticClass:"stepBox"},[_c('Steps',{attrs:{"current-val":_vm.stepInfo.currentVal,"error-step":_vm.stepInfo.errorStep,"step-array":_vm.stepInfo.list}})],1),_c('div',{staticClass:"userFooter",style:({
          color: _vm.memberInfo.userIdentity === 3 ? '#E6C0A2' : '#05040a',
        })},[_vm._v(" "+_vm._s(_vm.memberInfo.remind)+" ")])]),_c('div',{staticClass:"my-swipe"},[_c('swipers',{attrs:{"control":_vm.control}})],1)]):_vm._e(),_c('div',{ref:"tabsBox",staticClass:"tabsBox"},[(_vm.goodsList.length !== 0)?_c('ul',{staticClass:"tabsListBox"},_vm._l((_vm.goodsList.goodsInfo),function(item,index){return _c('li',{key:index,on:{"click":function($event){return _vm.gotoDetail(item)}}},[_c('div',{staticClass:"listImgBox"},[_c('img',{attrs:{"src":item.goodCover}}),(item.isShowIcon === 1)?_c('div',{staticClass:"vipTip"},[_c('img',{attrs:{"src":item.topIconImg}})]):_vm._e()]),_c('h3',{staticClass:"listTitle"},[_vm._v(" "+_vm._s(item.goodName)+" ")]),_c('div',{staticClass:"listPriceBox"},[_c('div',{staticClass:"listPrice"},[_c('h3',[_vm._v(" ¥"),_c('em',[_vm._v(_vm._s(item.goodVipPrice))]),_c('span',[_vm._v("¥"+_vm._s(item.goodPrice))])])]),_c('div',{staticClass:"growthValue"},[_c('img',{attrs:{"src":require("@/assets/images/feng-ye.png")}}),_vm._v("成长值"+_vm._s(item.growthValue)+" ")])])])}),0):_vm._e()]),_c('div',{staticClass:"dir"}),_c('Tabbar')],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="MustBuyBox">
    <div v-if="memberInfo" class="MustBuyTop">
      <div
        class="userInterestsBox"
        :style="{ backgroundImage: 'url(' + memberInfo.bgImg + ')' }"
      >
        <div class="userTopBox">
          <div class="Avatar">
            <img :src="memberInfo.userarvr">
          </div>
          <div class="memberInfo">
            <h2
              :style="{
                color: memberInfo.userIdentity === 3 ? '#E6C0A2' : '#05040a',
              }"
            >
              <div class="h2_box">{{ memberInfo.nickname }}</div>
              <span><img :src="memberInfo.icon">{{ memberInfo.levelName }}</span>
            </h2>
            <h4
              :style="{
                color: memberInfo.userIdentity === 3 ? '#E6C0A2' : '#05040a',
              }"
            >
              {{ memberInfo.describe }}
            </h4>
          </div>
        </div>

        <div
          class="userGrowth"
          :style="{
            color: memberInfo.userIdentity === 3 ? '#E6C0A2' : '#05040a',
          }"
        >
          当前成长值：<span
            :style="{
              color: memberInfo.userIdentity === 3 ? '#E6C0A2' : '#05040a',
            }"
          >{{ memberInfo.userGrowth }}</span>
          <div
            v-if="memberInfo.levelName != '黑金VIP'"
            class="gotoupgrade"
            @click="gotoupgrade"
          >
            升级黑金VIP
          </div>
        </div>

        <div class="stepBox">
          <Steps
            :current-val="stepInfo.currentVal"
            :error-step="stepInfo.errorStep"
            :step-array="stepInfo.list"
          />
        </div>

        <div
          class="userFooter"
          :style="{
            color: memberInfo.userIdentity === 3 ? '#E6C0A2' : '#05040a',
          }"
        >
          {{ memberInfo.remind }}
        </div>
      </div>
      <div class="my-swipe">
        <swipers :control="control" />
      </div>
      <!-- <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(leve, index) in levelList" :key="index">
          <div class="levelListBox">
            <div class="currentLevel" v-if="leve.isCurrentLevel === '1'">
              <img src="@/assets/images/isCurrentLevel.png" /> -->
      <!-- {{ leve.isCurrentLevel}} -->
      <!-- </div>
            <div class="levelTitle">
              {{ leve.levelName
              }}<span>{{ leve.iconListInfo.equityAmount }}项</span>权益
            </div>
            <ul>
              <li v-for="(icon, i) in leve.iconListInfo.iconList" :key="i"> -->
      <!-- {{i}} -->
      <!-- <img :src="icon.iconImg" />
                <p>{{ icon.name }}</p> -->
      <!-- {{icon.iconImg}} -->
      <!-- </li>
            </ul>
          </div>
        </van-swipe-item>
      </van-swipe> -->

      <!-- 必买爆款 -->
    </div>

    <div ref="tabsBox" class="tabsBox">
      <ul v-if="goodsList.length !== 0" class="tabsListBox">
        <li
          v-for="(item, index) in goodsList.goodsInfo"
          :key="index"
          @click="gotoDetail(item)"
        >
          <div class="listImgBox">
            <img :src="item.goodCover">
            <div v-if="item.isShowIcon === 1" class="vipTip">
              <img :src="item.topIconImg">
            </div>
          </div>
          <h3 class="listTitle">
            {{ item.goodName }}
          </h3>
          <div class="listPriceBox">
            <div class="listPrice">
              <h3>
                ¥<em>{{ item.goodVipPrice }}</em><span>¥{{ item.goodPrice }}</span>
              </h3>
            </div>
            <div class="growthValue">
              <img src="@/assets/images/feng-ye.png">成长值{{
                item.growthValue
              }}
            </div>
          </div>
          <!-- {{ i }} -->
        </li>
      </ul>
    </div>
    <div class="dir" />
    <Tabbar />
  </div>
</template>
<script>
import Tabbar from '@/component/Tabbar'
import Vue from 'vue'
import { Swipe, SwipeItem, Tab, Tabs } from 'vant'
import swipers from '@/component/swiper/swiper'

Vue.use(Tab)
Vue.use(Tabs)

Vue.use(Swipe).use(SwipeItem).use(Tab).use(Tabs)
import Steps from './component/Steps'
import { getGiftGoods, newMembershipLevelList } from '@/services/goods.js'
import { wxConfigInit } from '@/utils/wx-api'

export default {
  name: 'MustBuy',
  components: {
    Steps,
    Tabbar,
    swipers
  },
  data() {
    return {
      control: {
        list: []
        // 如果需要点击事件的话解开
        // ckickitem:(item)=>{

        // }
      },
      Bgimg: require('../../assets/images/user0.png'),
      goodsList: [],
      levelList: [],
      memberInfo: null,
      stepInfo: {
        list: [
          { status: '0', statusTime: '普通会员' },
          { status: '100', statusTime: '金卡会员' },
          { status: '800', statusTime: '白金会员' },
          { status: '1000', statusTime: '黑金会员' }
        ],
        errorStep: 0,
        currentVal: 2
      }
    }
  },
  mounted() {
    const shareInfo = {
      title: '悦淘会员，精彩无限购', // 分享标题
      desc: '专享16大权益', // 分享描述
      link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      imgUrl:
        'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-05-21/02/yuelvhuijvQ16Mgr6Z1589999698.jpg' // 分享图标
    }
    // this.$store.dispatch('wxConfigInit', shareInfo)
    wxConfigInit(shareInfo)
    this.getGiftGoods()
    if (localStorage.getItem('uid')) {
      this.newMembershipLevelList()
    }
  },
  methods: {
    gotoupgrade() {
      this.$refs.tabsBox.scrollIntoView({
        behavior: 'smooth'
      })
    },

    // 跳转详情
    gotoDetail(item) {
      this.$router.push({
        path: '/goodsDetailZY',
        query: {
          goodsId: item.goodId,
          skuid: item.skuId,
          activityId: item.activityId
        }
      })
    },

    newMembershipLevelList() {
      const body = {
        // uid:'15795'
        uid: localStorage.getItem('uid') || '' // 用户id
      }
      newMembershipLevelList(body).then((res) => {
        if (Number(res.code) === 200) {
          this.levelList = res.data.levelList
          this.control.list = res.data.levelList
          res.data.memberInfo.userarvr = localStorage.getItem('userarvr') || ''
          this.memberInfo = res.data.memberInfo
          this.stepInfo.currentVal = res.data.memberInfo.userIdentity
        }
      })
    },
    // 获取商品列表
    getGiftGoods() {
      const body = {
        uid: localStorage.getItem('uid') || '', // 用户id
        categoryId: '21',
        activityId: '31,320'
      }
      getGiftGoods(body).then((res) => {
        if (Number(res.code) === 200) {
          this.goodsList = res.data[0]
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
// #app {
//   height: 100%;
// }
// html,
// body {
//   height: 100%;
// }

.MustBuyBox {
  width: 100%;
  // width: 375px;
  height: 100%;

  .MustBuyTop {
    background: #181515;
    width: 100%;
    // width: 375px;
    height: auto;
    padding: 15px;

    .userInterestsBox {
      width: 100%;
      height: 216px;
      background-size: 100%;
      margin-bottom: 15px;
      padding: 15px 15px 0 15px;
      position: relative;

      .userTopBox {
        //   background: #000;

        // position: relative;
        width: 100%;
        height: 70px;
        text-align: left;

        .Avatar {
          width: 46px;
          height: 46px;
          // padding: 5px;

          box-sizing: border-box;
          // background: #fff;
          border-radius: 46px;
          overflow: hidden;
          float: left;
          margin-right: 10px;
          margin-top: 7px;

          img {
            width: 46px;
            height: 46px;
            border-radius: 46px;
            border: 3px solid #fff;
          }
        }

        .memberInfo {
          float: left;
          line-height: 20px;
          width: 51%;
          //overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: left;
          // padding: 5px 0;

          h2 {
            font-size: 16px;
            font-weight: bold;

            .h2_box {
              width: 80px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              float: left;
              margin-top: 8px;
            }

            span {
              width: 25%;
              height: 36px;
              background: #05040a;
              font-size: 10px;
              line-height: 36px;
              color: #a9a9a9;
              border-radius: 50px;
              vertical-align: middle;
              padding: 2px 6px;

              img {
                width: 14px;
                height: 14px;
                vertical-align: middle;
              }
            }
          }

          h4 {
            font-size: 10px;
          }
        }
      }

      .userGrowth {
        position: relative;
        text-align: left;
        padding: 10px 0;

        .gotoupgrade {
          position: absolute;
          //top: 0;
          //right: 0px;
          top: -58px;
          right: -5px;
          // width: 100px;
          font-size: 10px;
          height: 30px;
          background: #fc3f4d;
          color: #fff;
          line-height: 30px;
          padding: 0 6px;
          border-radius: 15px;
        }

        span {
          font-size: 22px;
          font-weight: bold;
          color: #05040a;
          vertical-align: middle;
        }

        //   background: #000;
      }
    }

    .stepBox {
      text-align: left;
    }

    .userFooter {
      // background: #000;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      // height: 30px;
      padding: 5px 10px;
      font-size: 14px;
      //font-weight: bold;
      text-align: left;
      line-height: 30px;
    }

    .my-swipe .van-swipe-item {
      color: #fff;
      height: 500px;
      font-size: 20px;
      //   line-height: 150px;
      text-align: center;

      .levelListBox {
        .currentLevel {
          //   width: ;
          width: 75px;
          height: 63px;
          position: absolute;
          top: 0;
          left: 0;
        }

        .levelTitle {
          width: 100%;
          padding: 0 0 20px 0;
          text-align: center;
          color: #fff;

          span {
            color: rgba(236, 190, 158, 1);
          }
        }

        position: relative;
        width: 100%;
        border: 1px solid #fff;
        padding: 20px 0 10px 0;
        border-radius: 10px;
        // height: 800px;
        ul {
          width: 100%;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-wrap: wrap;
          flex-wrap: wrap;

          li {
            //    width: 25%;
            width: 25%;
            height: 95px;
            text-align: center;
            // background: #000;
            img {
              width: 55px;
              height: 45px;
              //   height: 100%;
            }

            p {
              text-align: center;
              font-size: 12px;
              font-weight: bold;
              margin: 10px 0;
            }
          }
        }
      }

      //   background-color: #39a9ed;
    }
  }

  .tabsBox {
    background: #f6f6f6;
    padding: 15px;

    .tabsListBox {
      li {
        background: #fff;
        width: 100%;
        height: 274px;
        border-radius: 10px;
        margin-bottom: 15px;
        padding: 8px;
        box-sizing: border-box;

        .listImgBox {
          width: 100%;
          height: 185px;
          background: #ccc;
          position: relative;
          overflow: hidden;

          .vipTip {
            width: 78px;
            height: 32px;
            position: absolute;
            top: 0;
            left: 15px;

            img {
              width: 78px;
              height: 32px;
            }
          }
        }

        .listTitle {
          width: 100%;
          padding: 15px;
          font-size: 15px;
          font-weight: bold;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: left;
        }

        .listPriceBox {
          background: #000;
          width: 100%;

          .listPrice {
            width: 70%;
            float: left;
            text-align: left;

            h3 {
              color: #b1291a;
              font-size: 18px;

              em {
                font-size: 24px;
                font-style: normal;
              }

              span {
                font-size: 12px;
                font-weight: 400;
                color: #999999;
                text-decoration: line-through;
              }
            }
          }

          .growthValue {
            float: right;
            min-width: 95px;
            padding: 0 5px;
            height: 22px;
            background: #1a1716;
            border-radius: 11px;
            line-height: 22px;
            text-align: left;
            color: #fff;
            font-size: 12px;
            font-family: PingFang SC Regular, PingFang SC Regular-Regular;
            font-weight: 400;

            img {
              width: auto;
              height: 18px;
              margin-right: 5px;
              vertical-align: middle;
            }
          }
        }
      }
    }
  }
}

.dir {
  height: 40px;
}
</style>
